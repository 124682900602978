import {LocaleModel} from '@ui/schemes/app/locale.model'

const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    admission: {
      university_map: {
        tau: {
          desc_1: '',
          desc_2: 'өзіне сенетін және табысқа<br>жетуге ұмытылатындар үшін!',
          desc_3: '',
          address: 'Астана, Дүкенұлы к-сі, 29А',
          phone: '+7 (702) 912 39 97',
        },
        uib: {
          desc_1: '',
          desc_2: 'болашақ үшін үздік таңдау!',
          desc_3: '',
          address: 'Алматы, Абай даңғылы, 8а',
          phone: '+ 7 (771) 949 99 11',
        },
        etu: {
          desc_1: 'ҰБТ-да 50 ұпай жинап,',
          desc_2: 'университетіне оқуға түсіңіз',
          desc_3: '',
          address: 'Алматы, Төле би к-сі, 109Б',
          phone: '+7 (777) 829 00 11',
        },
        kau: {
          desc_1: '',
          desc_2: '1 жылда 12 мамандық',
          desc_3: '',
          address: 'Алматы, Рысқұлбек к-сі, 28',
          phone: '+ 7 (775) 096 3283',
        },
        mnu: {
          desc_1: '',
          desc_2: 'Университетінің Қабылдау<br>комиссиясы өз жұмысын бастады',
          desc_3: 'Құжаттарды 1 маусымнан<br>25 тамызға дейін тапсыруға болады',
          address: 'Астана, Қорғалжын тас жолы, 8',
          phone: '+7 (700) 170-30-30',
        },
        iai: {
          desc_1: 'ҚР мектеп бітіруші түлектеріне арналған байқау',
          desc_2: 'Challenge-ды орындап - Institute of Artificial Intelligence by Korkyt Ata грантын жеңіп ал!',
          desc_3: '',
          address: 'Қызылорда қ., Назарбаева даңғылы, 13А',
          phone: '+7 (776) 538 88 99',
        },
        alt: {
          desc: 'Сенің арманыңдағы university',
          address: 'Алматы, Шевченко к-сі, 97',
        },
      },
      more_info_btn: 'Толығырақ білгім келеді',
    },
    hs: {
      edu: 'Дайындық',
      city_select: 'Қаланы таңдаңыз',
    },
  },
}

export default localeKk

import {LocaleModel} from '@ui/schemes/app/locale.model'

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    admission: {
      university_map: {
        tau: {
          desc_1: '',
          desc_2: 'для тех, кто верит в себя<br>и стремится к успеху!',
          desc_3: '',
          address: 'Астана, ул. Дукенулы, 29А',
          phone: '+7 (702) 912 39 97',
        },
        uib: {
          desc_1: '',
          desc_2: 'лучший выбор для будущего!',
          desc_3: '',
          address: 'Алматы, проспект Абая, 8а',
          phone: '+ 7 (771) 949 99 11',
        },
        etu: {
          desc_1: 'Наберите 50 баллов на ЕНТ<br>и поступите в',
          desc_2: '',
          desc_3: '',
          address: 'Алматы, ул. Толе би, 109Б',
          phone: '+7 (777) 829 00 11',
        },
        kau: {
          desc_1: '',
          desc_2: '12 специальностей за 1 год',
          desc_3: '',
          address: 'Алматы, ул. Рыскулбекова, 28',
          phone: '+ 7 (775) 096 3283',
        },
        mnu: {
          desc_1: '',
          desc_2: 'Приемная комиссия университета приступила к работе',
          desc_3: 'Документы можно подать<br>с 1 июня по 25 августа',
          address: 'Астана, Коргалжынское шоссе, 8',
          phone: '+7 (700) 170-30-30',
        },
        iai: {
          desc_1: 'Конкурс для выпускников средних школ РК',
          desc_2: 'Выполни Challenge – выиграй грант Institute of Artificial Intelligence by Korkyt Ata!',
          desc_3: '',
          address: 'г. Кызылорда, проспект Назарбаева, 13А',
          phone: '+7 (776) 538 88 99',
        },
        alt: {
          desc: 'University твоей мечты',
          address: 'Алматы, ул. Шевченко, 97',
        },
      },
      more_info_btn: 'Хочу узнать больше',
    },
    hs: {
      edu: 'Дайындық',
      city_select: 'Қаланы таңдаңыз',
    }
  },
}

export default localeRu

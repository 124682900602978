import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'
import {BehaviorSubject, Observable, Subject} from 'rxjs'
import amplitude from 'amplitude-js'
import moment, {Moment} from 'moment'
import 'moment/locale/kk'
import 'moment/locale/ru'

import {DEFAULT_INSETS, DEFAULT_LANG, DEFAULT_THEME, MOBILE_ACCESS_TOKEN, MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE, MOBILE_LANG, MOBILE_SAFE_AREA_INSETS, MOBILE_THEME} from '@ui/constants/constants'
import {Token} from '@ui/schemes/ro/token'
import {rnLog, rnWebviewPostMessageOnLoad} from '@ui/utils/rn-webview'
import {Profile} from '@ui/schemes/ro/profile'
import {retrieveTokenExpireMoment} from '@ui/utils/auth'
import {ProfileService} from '@ui/services/profile.service'

import localeKk from '../../assets/i18n/kk'
import localeRu from '../../assets/i18n/ru'

const hasRnToken = () => !!window[MOBILE_ACCESS_TOKEN]

@Injectable({
  providedIn: 'root',
})
export class AppState {

  private _initialized = new BehaviorSubject<boolean>(false)
  private _lang: string
  private _token: string
  private _tokenExpireMoment: Moment
  private _profile: Profile

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private profileService: ProfileService,
  ) {
    this.initRnObservables()
  }

  startAppInit(): void {
    this.overrideRnSettingsIfNeeded()

    this.waitRnDataInjection()
      .then(() => {
        this.saveRnData()
        this.finishAppInit()
        rnWebviewPostMessageOnLoad()
      })
      .catch(() => {
        this.finishAppInit()
        this.router.navigate(['/error'], {queryParams: {status: 'cannot_get_mobile_data'}})
      })
  }

  overrideRnSettingsIfNeeded(): void {
    if (process.env.NG_APP_OVERRIDE_RN_SETTINGS !== 'true') {
      return
    }
    try {
      window['ReactNativeWebView'] = {postMessage: (data: any) => null}
      window[MOBILE_LANG] = process.env.NG_APP_LANG ?? DEFAULT_LANG
      window[MOBILE_THEME] = process.env.NG_APP_THEME ?? DEFAULT_THEME
      window[MOBILE_ACCESS_TOKEN] = process.env.NG_APP_ACCESS_TOKEN ?? null
      window[MOBILE_SAFE_AREA_INSETS] = JSON.parse(process.env.NG_APP_SAFE_AREA_INSETS ?? DEFAULT_INSETS)
    } catch (error) {
      console.error('Error while overriding RN settings using local environment file:', error)
    }
  }

  waitRnDataInjection(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (hasRnToken()) {
        rnLog('RN data was injected immediately.')
        resolve()
      }

      /**
       * Below code is used to prevent undefined values injected by RN.
       * There have been cases in the past when the values were only available after a while.
       * Therefore, to ensure that these values are successfully received, we use a timeout of five seconds.
       */

      let retry = 0
      const max = 5000

      const interval = setInterval(() => {
        if (hasRnToken()) {
          rnLog('RN data was injected after sometime.')
          clearInterval(interval)
          resolve()
        } else if (retry === max) {
          rnLog('Timeout error since RN did not inject mobile data.')
          clearInterval(interval)
          reject()
        } else {
          rnLog(`[${retry}] Could not get RN data, retrying process...`)
          retry++
        }
      }, 1)
    })
  }

  saveRnData(): void {
    this.setLang(window[MOBILE_LANG])
    this.setToken(window[MOBILE_ACCESS_TOKEN])
  }

  finishAppInit(): void {
    amplitude.getInstance().init('924a9ad574e77e1a7d176fe44f88acfa')

    this.profileService.profile$.subscribe({
      next: profile => {
        this._profile = profile
        amplitude.getInstance().setUserId(profile.id)
      },
      complete: () => {
        this._initialized.next(true)
      },
    })
  }

  initRnObservables(): void {
    window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE] = new Subject()
    this.onMobileAppFinishRefreshingToken$.subscribe((token: Token) => this.setToken(token.access))
  }

  // getters

  get onMobileAppFinishRefreshingToken$(): Observable<Token> {
    return window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE].asObservable()
  }

  get initialized$(): Observable<boolean> {
    return this._initialized.asObservable()
  }

  get initialized(): boolean {
    return this._initialized.getValue()
  }

  get lang(): string {
    return this._lang
  }

  get token(): string {
    return this._token
  }

  get tokenExpireMoment(): Moment {
    return this._tokenExpireMoment
  }

  get profile(): Profile {
    return this._profile
  }

  // setters

  setLang(lang: string): void {
    this._lang = lang
    moment.locale(lang)

    this.translateService.addLangs(['kk', 'ru'])
    this.translateService.setDefaultLang(lang)
    this.translateService.use(lang);

    [localeKk, localeRu].forEach(locale => {
      this.translateService.setTranslation(locale.lang, locale.data, true)
    })
  }

  setToken(token: string): void {
    this._token = token
    this._tokenExpireMoment = retrieveTokenExpireMoment(token)
  }
}

import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

import {AppGuard} from '@wv-banners/guards/app.guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: 'admission/alt',
        loadComponent: () => import('./pages/admission-alt/admission-alt.component').then(r => r.AdmissionAltComponent),
      },
      {
        path: 'admission/:universityId',
        loadComponent: () => import('./pages/admission/admission.component').then(r => r.AdmissionComponent),
      },
      {
        path: 'hs',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/hs/home/hs-home.component').then(r => r.HsHomeComponent),
          },
          {
            path: 'cities',
            loadComponent: () => import('./pages/hs/cities/hs-cities.component').then(r => r.HsCitiesComponent),
          },
        ],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

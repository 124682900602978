import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router'
import {filter, takeWhile} from 'rxjs'

import {rnLog} from '@ui/utils/rn-webview'

import {AppState} from '@wv-banners/states/app.state'

@Injectable()
export class AppGuard implements CanActivate {

  constructor(
    private appService: AppState,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.appService.initialized) {
      rnLog('[AppGuard] App is already initialized, navigating page.')
      return true
    }

    this.appService.startAppInit()

    return this.appService.initialized$
      .pipe(
        takeWhile(init => !init, true),
        filter(init => init),
      )
  }
}

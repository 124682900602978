import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {HashLocationStrategy, LocationStrategy} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'

import {AppGuard} from '@wv-banners/guards/app.guard'
import {AuthInterceptor} from '@wv-banners/interceptors/auth.interceptor'

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppGuard
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
